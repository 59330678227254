<template>
  <div id="print" style="color:black;">
    <!-- 标题 -->
    <p class="title">上海奥吉实业有限公司</p>
    <p class="title">销售入库单</p>

    <!-- 信息 -->
    <div style="margin-bottom: 10px;">
      <a-row>
        <a-col :span="8"></a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          入库号：{{ orderData.purchase_num }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          供应商：{{ orderData.supplier_name }}
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          入库日期：{{ orderData.purchase_date }}
        </a-col>
      </a-row>
    </div>

    <!-- 表格 -->
    <div>
      <table style="border-color: #000;">
        <thead>
          <tr>
            <th style="border-color: #000;">序号</th>
            <th style="border-color: #000;">编码</th>
            <th style="border-color: #000;">品名</th>
            <th style="border-color: #000;">规格</th>
            <th style="border-color: #000;">数量</th>
            <th style="border-color: #000;">单位</th>
            <th style="border-color: #000;">单价</th>
            <th style="border-color: #000;">去税总价</th>
            <th style="border-color: #000;">税额</th>
            <th style="border-color: #000;">总金额</th>
            <th style="border-color: #000;">备注</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in orderList" :key="index">
            <td style="border-color: #000;">{{ item.index+1 ? item.index+1 : '' }}</td>
            <td style="border-color: #000;">{{ item.good_no }}</td>
            <td style="border-color: #000;">{{ item.good_name }}</td>
            <td style="border-color: #000;">{{ item.good_spec }}</td>
            <td style="border-color: #000;">{{ item.num }}</td>
            <td style="border-color: #000;">{{ item.unit }}</td>
            <td style="border-color: #000;">{{ item.price }}</td>
            <td style="border-color: #000;">{{ item.price_tax }}</td>
            <td style="border-color: #000;">{{ item.tax }}</td>
            <td style="border-color: #000;">{{ item.sum_price }}</td>
            <td style="border-color: #000;">{{ item.remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 底部 -->
    <p id="footer">
      <span>收货人：张洁</span><span>审批人：陈瑞雯</span>
    </p>
  </div>
</template>

<script>
export default {
  name: `SalePurchaseSheetBill`,
  props: {
    currentData: { type: Object, default: null },
    balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val;
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: []
    }
  },
  methods: {
    initData() {
      // 初始化数据
      this.orderData = this.currentData;
      this.orderList = this.balanceData;
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
.title{ text-align: center; font-weight: bold; font-size: 16px; }
#footer span { display: inline-block; width: 20%; margin-top: 10px; }
#footer span:last-child { width: 30% }
</style>
